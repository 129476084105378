import React from "react";
import { lazy } from "react";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { ViewCompact } from "@material-ui/icons";
import ThreeBarIcon from "components/Icons/ThreeBarIcon";
import TransactionPaperIcon from "components/Icons/TransactionPaperIcon";
import DashboardIcon from "components/Icons/DashboardIcon";

const Grades = lazy(() => import("views/Grades"));
const EditStudents = lazy(() => import("views/EditStudents"));
const ClassRecord = lazy(() => import("views/ClassRecord"));
const StudentProfile = lazy(() => import("views/StudentProfile"));
const SectionsPage = lazy(() => import("views/SectionsPage"));
const Section = lazy(() => import("views/SectionsPage/components/Section"));
const AttendancePage = lazy(() => import("views/Attendnce"));
const DashboardPage = lazy(() => import("views/Dashboard/Dashboard"));
const Users = lazy(() => import("views/Users/Users"));
const Schools = lazy(() => import("views/Schools/Schools"));
const SchoolYears = lazy(() => import("views/SchoolYears/SchoolYears.jsx"));
const Teachers = lazy(() => import("views/Teachers/Teachers"));
const SchoolLevels = lazy(() => import("views/SchoolLevels/SchoolLevels"));
const Subjects = lazy(() => import("views/Subjects/Subjects"));
const Courses = lazy(() => import("views/Courses/Courses"));
const ClassSchedules = lazy(() => import("views/ClassSchedules/ClassSchedules"));
const Parents = lazy(() => import("views/Parents/Parents"));
const Payments = lazy(() => import("views/Payments/Payments"));
const StudentsPage = lazy(() => import("views/StudentsPage"));
const BranchesPage = lazy(() => import("views/Branches/Branches"));
const ReportCard = lazy(() => import("views/ReportCard"));
const Roles = lazy(() => import("views/Roles"));
const StudentsList = lazy(() => import("views/StudentsList/List.jsx"));
const TeacherClassAttendance = lazy(() => import("views/ClassAttendance"));
const PayableTemplate = lazy(() => import("views/PayableTemplates"));
const PayableTemplateForm = lazy(() =>
    import("views/PayableTemplates/components/PayableTemplateForm")
);

const ROUTES = {
    DASHBOARD: {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardPage,
        permission: "VIEW_TAB_DASHBOARD",
    },
    ENROLLED_STUDENTS: {
        path: "/students",
        name: "Students",
        component: StudentsPage,
        permission: "VIEW_TAB_STUDENTS",
    },
    SCHOOLS: {
        path: "/schools",
        name: "Schools",
        component: Schools,
        permission: "VIEW_TAB_SCHOOLS",
    },
    SCHOOL_YEARS: {
        path: "/school-years",
        name: "School Years",
        icon: "content_paste",
        component: SchoolYears,
        permission: "VIEW_TAB_SCHOOL_YEARS",
    },
    GRADES: {
        path: "/grades",
        name: "Grades",
        component: Grades,
        permission: "VIEW_TAB_GRADES",
    },
    PAYMENTS: {
        path: "/payments",
        name: "Payments",
        component: Payments,
        permission: "VIEW_TAB_PAYMENTS",
    },
    TEACHERS: {
        path: "/teachers",
        name: "Teachers",
        component: Teachers,
        permission: "VIEW_TAB_TEACHERS",
    },
    PARENTS: {
        path: "/parents",
        name: "Parents",
        component: Parents,
        permission: "VIEW_TAB_PARENTS",
    },
    SCHOOL_LEVELS: {
        path: "/school-levels",
        name: "School Levels",
        component: SchoolLevels,
        permission: "VIEW_TAB_SCHOOL_LEVEL",
    },
    SUBJECTS: {
        path: "/subjects",
        name: "Subjects",
        component: Subjects,
        permission: "VIEW_TAB_SUBJECTS",
    },
    COURSES: {
        path: "/courses",
        name: "Courses",
        component: Courses,
        permission: "VIEW_TAB_COURSES",
    },
    CLASS_RECORD: {
        path: "/class-record",
        name: "Class Record",
        component: ClassRecord,
        permission: "VIEW_TAB_CLASS_RECORD",
    },
    CLASS_SCHEDULES: {
        path: "/class-schedules",
        name: "Class Schedules",
        component: ClassSchedules,
        permission: "VIEW_TAB_CLASS_SCHEDULES",
    },
    SECTIONS: {
        path: "/sections",
        name: "Sections",
        component: SectionsPage,
        permission: "VIEW_TAB_SECTIONS",
    },
    USERS: {
        path: "/users",
        name: "Users",
        component: Users,
        permission: "VIEW_TAB_USERS",
    },
    ATTENDANCE: {
        path: "/attendance",
        name: "ID Scan Logs",
        component: AttendancePage,
        permission: "VIEW_TAB_ID_SCAN_LOGS",
    },
    BRANCHES: {
        path: "/branches",
        name: "Branches",
        component: BranchesPage,
        permission: "VIEW_TAB_BRANCHES",
    },
    REPORT_CARD: {
        path: "/report-card",
        name: "Report Card",
        component: ReportCard,
        permission: "VIEW_TAB_REPORT_CARD",
    },
    ROLES: {
        path: "/roles",
        name: "Roles",
        component: Roles,
        permission: "VIEW_TAB_ROLES",
    },
    STUDENTS_LIST: {
        path: "/students-list",
        name: "Students",
        component: StudentsList,
        permission: "VIEW_TAB_STUDENTS_LIST",
    },
    TEACHER_CLASS_ATTENDANCE: {
        path: "/teacher-attendance",
        name: "Attendance",
        component: TeacherClassAttendance,
        permission: "VIEW_TAB_TEACHER_CLASS_ATTENDANCE",
    },
    PAYABLE_TEMPLATES: {
        path: "/payables",
        name: "Payable Templates",
        component: PayableTemplate,
        permission: "VIEW_TAB_PAYABLE_TEMPLATES",
    },
};

export const duplicatedRoutes = [
    {
        path: "/",
        name: "Dashboard",
        rtlName: "",
        component: DashboardPage,
        layout: "",
        permission: "VIEW_TAB_DASHBOARD",
    },
];

export const subRoutes = [
    {
        path: "/students/:type",
        name: "Student Enrollment Form",
        component: EditStudents,
        permission: "ADD_STUDENT",
    },
    {
        path: "/students/:type/:id",
        name: "Edit Student",
        component: EditStudents,
        permission: "UPDATE_STUDENT",
    },
    {
        path: "/student-profile/:id",
        name: "Student Profile",
        component: StudentProfile,
        permission: "VIEW_STUDENT",
    },
    {
        path: "/section/:id",
        name: "Section",
        component: Section,
        permission: "VIEW_SECTIONS",
    },
    {
        path: "/payables/add",
        name: "Create Payable Template",
        component: PayableTemplateForm,
        permission: "VIEW_ADD_PAYABLE_TEMPLATES",
    },
    {
        path: "/payables/edit/:id",
        name: "Create Payable Template",
        component: PayableTemplateForm,
        permission: "VIEW_EDIT_PAYABLE_TEMPLATES",
    },
];

export const LINKS = [
    {
        name: "Dashboard",
        route: ROUTES.DASHBOARD,
        subRoutes: [],
        permission: "VIEW_TAB_DASHBOARD",
        icon: <DashboardIcon />,
    },
    {
        name: "Payable Templates",
        route: ROUTES.PAYABLE_TEMPLATES,
        subRoutes: [],
        permission: "VIEW_TAB_PAYABLE_TEMPLATES",
        icon: <ThreeBarIcon />,
    },
    {
        name: "Students",
        route: ROUTES.STUDENTS_LIST,
        subRoutes: [],
        permission: "VIEW_TAB_STUDENTS_LIST",
        icon: (
            <>
                <i className="fa fa-users" />
            </>
        ),
    },
    {
        name: "Attendance",
        route: ROUTES.TEACHER_CLASS_ATTENDANCE,
        subRoutes: [],
        permission: "VIEW_TAB_TEACHER_CLASS_ATTENDANCE",
        icon: (
            <>
                <i className="far fa-calendar-alt"></i>
            </>
        ),
    },
    {
        name: "Administrative",
        subRoutes: [
            ROUTES.BRANCHES,
            ROUTES.SCHOOL_YEARS,
            ROUTES.SCHOOL_LEVELS,
            ROUTES.SECTIONS,
            ROUTES.SUBJECTS,
            ROUTES.CLASS_SCHEDULES,
            ROUTES.COURSES,
            ROUTES.TEACHERS,
        ],
        permission: "VIEW_TAB_ADMINISTRATIVE",
        icon: (
            <>
                <i className="fas fa-building" />
            </>
        ),
    },
    {
        name: "Student Enrollment",
        subRoutes: [ROUTES.ENROLLED_STUDENTS],
        permission: "VIEW_TAB_STUDENT_ENROLLMENT",
        icon: (
            <>
                <i className="fas fa-graduation-cap" />
            </>
        ),
    },
    {
        name: "Faculty Tools",
        subRoutes: [ROUTES.CLASS_RECORD, ROUTES.GRADES, ROUTES.REPORT_CARD],
        permission: "VIEW_TAB_FACULTY_TOOLS",
        icon: (
            <>
                <i className="fa fa-archive" />
            </>
        ),
    },
    {
        name: "Assessment",
        subRoutes: [ROUTES.PAYMENTS, ROUTES.PARENTS],
        permission: "VIEW_TAB_ASSESSMENT",
        icon: (
            <>
                <i className="fas fa-clipboard" />
            </>
        ),
    },
    {
        name: "System",
        subRoutes: [ROUTES.SCHOOLS, ROUTES.USERS, ROUTES.ATTENDANCE, ROUTES.ROLES],
        permission: "VIEW_TAB_SYSTEM",
        icon: (
            <>
                <FeatherIcon icon="settings" />
            </>
        ),
    },
];

export default ROUTES;
