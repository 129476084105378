import Alert from "@material-ui/lab/Alert";
import {
    GRADESLIP,
    INITIAL_EXPORT_FORM_VALUES,
    INITIAL_FORM_VALUES,
    LOWEST_GRADE,
    MAPEH,
    MAPEH_SUBJECT,
    MAPEH_SUBJECTS,
    MAPEH_SUBJECTS_ORDER,
    NOT_GRADED,
    NUMBER_REGEX,
    REPORTCARD,
    REPORT_CARD_INITIAL_FORM_VALUES,
    STRING,
    SUBJECT_ORDER,
    TRANSMUTATION_TABLE,
    UNDEFINED,
} from "config/constants";
import { Skeleton } from "@mui/material";
import firebase from "firebase/app";
import "firebase/storage";
import _, { forEach, isUndefined } from "lodash";
import moment from "moment";
import { string } from "prop-types";
import React from "react";

const Timestamp = firebase.firestore.Timestamp;

export const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validateFields = state => {
    let newFields = state.fields;
    const emptyKeys = state.emptyKeys || [];
    const emailKeys = state.emailKeys || [];
    let errorKey = "";

    Object.keys(newFields).map(v => {
        const { [v]: field } = newFields || {};
        newFields = {
            ...newFields,
            [v]: {
                ...field,
                errorMessage: "",
            },
        };
        return v;
    });

    try {
        if ("password" in newFields && "confirmPassword" in newFields) {
            if (newFields.password.value !== newFields.confirmPassword.value) {
                errorKey = "confirmPassword";
                newFields = {
                    ...newFields,
                    confirmPassword: {
                        ...newFields.confirmPassword,
                        errorMessage: "Password and Confirm password does not match.",
                    },
                };
            }
        }
    } catch (error) {
        console.error(`[Error] ${error.name}: ${error.message}`);
    }

    // emailValidators
    emailKeys.map(v => {
        const { [v]: field } = newFields || {};
        if (!validateEmail(field.value)) {
            errorKey = v;
            newFields = {
                ...newFields,
                [v]: {
                    ...field,
                    errorMessage: "Invalid Email Address.",
                },
            };
        }
        return v;
    });

    // requiredFields
    emptyKeys.map(v => {
        const { [v]: field } = newFields || {};
        if (_.isEmpty(field.value)) {
            errorKey = v;
            newFields = {
                ...newFields,
                [v]: {
                    ...field,
                    errorMessage: `${field.name} is required!`,
                },
            };
        }
        return v;
    });

    return {
        errorKey,
        newFields,
    };
};

export const generatePushID = (function() {
    // Modeled after base64 web-safe chars, but ordered by ASCII.
    var PUSH_CHARS = "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

    // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
    var lastPushTime = 0;

    // We generate 72-bits of randomness which get turned into 12 characters and appended to the
    // timestamp to prevent collisions with other clients.  We store the last characters we
    // generated because in the event of a collision, we'll use those same characters except
    // "incremented" by one.
    var lastRandChars = [];

    return function() {
        var now = new Date().getTime();
        var duplicateTime = now === lastPushTime;
        lastPushTime = now;

        var timeStampChars = new Array(8);
        for (var i = 7; i >= 0; i--) {
            timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
            // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
            now = Math.floor(now / 64);
        }
        if (now !== 0) throw new Error("We should have converted the entire timestamp.");

        var id = timeStampChars.join("");

        if (!duplicateTime) {
            for (i = 0; i < 12; i++) {
                lastRandChars[i] = Math.floor(Math.random() * 64);
            }
        } else {
            // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
            for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
                lastRandChars[i] = 0;
            }
            lastRandChars[i]++;
        }
        for (i = 0; i < 12; i++) {
            id += PUSH_CHARS.charAt(lastRandChars[i]);
        }
        // if(id.length !== 20) throw new Error('Length should be 20.');

        return id;
    };
})();

export const uid = new Date().getTime().toString();

export const renderErrorMesage = (key, fields) => {
    return fields[key].errorMessage && <Alert severity="error">{fields[key].errorMessage}</Alert>;
};

export const renderErrorMessageYup = (error, touch) => {
    return error && touch && <Alert severity="error">{error}</Alert>;
};

export const uniquify = myArray => {
    return [...new Set(myArray)];
};

export const setTags = s => {
    s = s.trim().toLowerCase();

    if (s === "") return [];

    let tags = [];
    tags.push(s);
    s = s.replace(/[\W_]+/g, " ");
    s = s.split(" ");

    s.forEach(item => {
        tags.push(item);
    });

    let l = s.length;
    let i = 0;
    while (i < l) {
        try {
            tags.push(s[i]);
            let t = "";

            for (let x = 0; x < s[i].length; x++) {
                t += s[i][x];
                tags.push(t);
            }
        } catch (error) {
            console.error(`[Error] ${error.name}: ${error.message}`);
        }
        i++;
    }

    i = 0;
    while (i < l) {
        const item = s[i] + " " + s[i + 1];
        if (!item.includes(UNDEFINED)) tags.push(item);
        i++;
    }

    i = 0;
    while (i < l) {
        const item = s[i] + " " + s[i + 1] + " " + s[i + 2];
        if (!item.includes(UNDEFINED)) tags.push(item);
        i++;
    }

    i = 0;
    while (i < l) {
        const item = s[i] + " " + s[i + 1] + " " + s[i + 2] + " " + s[i + 3];
        if (!item.includes(UNDEFINED)) tags.push(item);
        i++;
    }

    i = 0;
    while (i < l) {
        const item = s[i] + " " + s[i + 1] + " " + s[i + 2] + " " + s[i + 3] + " " + s[i + 4];
        if (!item.includes(UNDEFINED)) tags.push(item);
        i++;
    }

    return uniquify(tags);
};

export const setStringTags = stringsList => {
    let stringTagsList = [];
    if (stringsList && stringsList.length > 0) {
        stringsList.forEach((string, index) => {
            if (index > 0) {
                let counter = 0;
                let combinedString = "";
                while (counter < index) {
                    if (counter === 0) {
                        combinedString = stringsList[counter];
                    } else {
                        combinedString = `${combinedString} ${stringsList[counter]}`;
                    }
                    counter++;
                }
                setTags(string).forEach(stringItem => {
                    stringTagsList.push(`${combinedString} ${stringItem}`);
                });
            }
        });
    }
    return stringTagsList;
};

export const getSpecificTags = arrayOfValues => {
    let combinedString = "";

    arrayOfValues.forEach((item, index) => {
        if (item) {
            if (index === 0) combinedString = item;
            else combinedString = `${combinedString} ${item}`;
            combinedString = combinedString.trim();
        }
    });

    return uniquify(
        setStringTags(
            combinedString
                .trim()
                .toLowerCase()
                .split(" ")
        )
    );
};

export const getTags = arrayOfValues => {
    let tags = [];
    arrayOfValues.forEach(item => {
        if (item) tags.push(setTags(item));
    });
    return [].concat.apply([], uniquify(tags));
};

// provide array with first name, middle name, last name in proper order
// middle name is optional
export const getCombinedNameTags = arrayOfValues => {
    let nametagsList = [];
    // if middlename is provided
    if (arrayOfValues.length > 2) {
        let firstLastNameTags = getSpecificTags([arrayOfValues[0], arrayOfValues[2]]);
        let fullNameTags = getSpecificTags([arrayOfValues[0], arrayOfValues[1], arrayOfValues[2]]);
        nametagsList = uniquify(fullNameTags.concat(firstLastNameTags));
    } else {
        nametagsList = uniquify(getSpecificTags([arrayOfValues[0], arrayOfValues[1]]));
    }

    return uniquify(getTags(arrayOfValues).concat(nametagsList));
};

export const b64toBlob = dataURI => {
    let byteString = atob(dataURI.split(",")[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const bb = new Blob([ab], { type: "image/jpeg" });
    return bb;
};

export const getPhotoURLFromStorage = pathRef => {
    let storageRef = firebase.storage().ref();
    let imgRef = storageRef.child(pathRef);

    return imgRef.getDownloadURL().then(url => {
        return url;
    });
};

export const getBlobFromStorage = pathRef => {
    let storageRef = firebase.storage().ref();
    let imgRef = storageRef.child(pathRef);

    return imgRef.getDownloadURL().then(url => {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.onreadystatechange = evt => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    } else {
                        reject(new Error("Ajax error for " + url + ": " + xhr.status));
                    }
                }
            };
            xhr.send();
        });
    });
};

export const blobToB64 = (blob, callback) => {
    let reader = new FileReader();
    reader.onloadend = (e => {
        callback(reader.result);
    }).bind(this);
    reader.readAsDataURL(blob);
};

export const firestoreAutoId = (length = 20) => {
    const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let autoId = "";

    for (let i = 0; i < length; i++) {
        autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
    }
    return autoId;
};

export const getYearFromDateYYFormat = year => {
    return new Date(year)
        .getFullYear()
        .toString()
        .substr(-2);
};

export const toPHP = value => {
    const formatter = new Intl.NumberFormat("en-PH", {
        style: "currency",
        currency: "PHP",
    });

    return formatter.format(value);
};

export const moneyToIntegerDB = value => {
    return parseInt(value) * 100;
};

export const DBIntegerToMoney = value => {
    return parseInt(value) / 100;
};

export const searchTuitionFeeTypeId = paymentTypes => {
    return Object.keys(paymentTypes).find(id =>
        paymentTypes[id].name.toLowerCase().includes("tuition")
    );
};

export const SheetJSFT = [
    "xlsx",
    "xlsb",
    "xlsm",
    "xls",
    "xml",
    "csv",
    "txt",
    "ods",
    "fods",
    "uos",
    "sylk",
    "dif",
    "dbf",
    "prn",
    "qpw",
    "123",
    "wb*",
    "wq*",
    "html",
    "htm",
]
    .map(x => "." + x)
    .join(",");

export const toTitle = str => (str ? str.replace(/^\w/, c => c.toUpperCase()) : "");

export const getLastChar = str => str.slice(str.length - 1);

export const wordsSeparator = (wordsList, separator) => {
    // If user provides a custom separator as parameter, use that instead.
    // Default separator is ', '
    return wordsList.filter(Boolean).join(separator ? separator : ", ");
};

export const imagetoPrint = source => {
    return (
        "<html><head><scri" +
        "pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" +
        "pt></head><body onload='step1()'>\n" +
        "<img src='" +
        source +
        "' /></body></html>"
    );
};

export const isValidMobile = value =>
    (value === "" || NUMBER_REGEX.test(value)) && value.length < 13;

export const camelCaseToNormal = string => {
    string.replace(/([A-Z])/g, " $1");
    // uppercase the first character
    return string.replace(/^./, str => {
        return str.toUpperCase();
    });
};

export const setQueryConditions = (query, filters) => {
    Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== "all") {
            if (key === "search") {
                query = query.where("tags", "array-contains", value.toLowerCase());
            } else {
                query = query.where(key, "==", value);
            }
        }
    });
    return query;
};

export const decimalToPercentage = value => (+value * 100).toFixed(2);

export const to12HRTimeFormat = timeString =>
    new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString(
        {},
        { timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
    );

export const timestampToString = (timestamp, format = "LLLL") => {
    if (timestamp) {
        return moment(new Date(timestamp.seconds * 1000)).format(format);
    }
    return "N/A";
};

export const usePrevious = value => {
    const ref = React.useRef();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export const sortSchoolLevels = items => {
    const parsedItems = items.docs.map(item => {
        return { ...item.data(), id: item.id, docId: item.id };
    });

    return parsedItems
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
};

export const previewPhoto = filePhoto => {
    const reader = new FileReader();
    reader.readAsDataURL(filePhoto);
    let x;
    reader.onloadend = e => {
        x = reader.result;
    };
    return x;
};

export const getSubjGradingTypeEquivalent = (grade, type) => {
    if (grade > 89) {
        return type === "color" ? "Gold" : "O";
    } else if (grade > 84) {
        return type === "color" ? "Silver" : "VS";
    } else if (grade > 79) {
        return type === "color" ? "Bronze" : "S";
    } else if (grade > 74) {
        return type === "color" ? "Yellow" : "FS";
    } else {
        return type === "color" ? "White" : "DNME";
    }
};

export const getSubjGradingType = (id, subjectItems, numberGrade) => {
    let isSubjectLetterGrade;

    subjectItems.forEach(subject => {
        const subjectDocId = subject.documentId ? subject.documentId : subject.docId;

        if (subjectDocId === id) {
            const isLetterGrade = subject?.useLetterGrade;

            if (isLetterGrade !== undefined || null) {
                isSubjectLetterGrade = isLetterGrade;
            } else {
                isSubjectLetterGrade = false;
            }
        }
    });

    if (isSubjectLetterGrade) {
        return getSubjGradingTypeEquivalent(numberGrade, "Letter");
    }

    return numberGrade;
};

export const getLetterGrade = grade => {
    return getSubjGradingTypeEquivalent(grade, "letterGrade");
};

export const transmuteGrade = initialGrade => {
    const parsedGrade = Number.parseFloat(initialGrade.toFixed(2));
    let newInitialGrade;
    if (parsedGrade === 100.0) return 99;

    TRANSMUTATION_TABLE.forEach(range => {
        const { highGrade, lowGrade, tGrade } = range;
        if (parsedGrade <= highGrade && parsedGrade >= lowGrade) {
            newInitialGrade = tGrade;
        }
    });

    return convertToDecimal(newInitialGrade);
};

export const convertToDecimal = data => {
    return Number.parseFloat(data).toFixed(2);
};

export const sortCorrectedSubjects = (data, order) => {
    let res;
    if (data) {
        res = (data || []).sort((a, b) => {
            return (
                order.indexOf(
                    a?.name
                        .toUpperCase()
                        .replace(/\s+/g, "")
                        .trim()
                ) -
                order.indexOf(
                    b?.name
                        .toUpperCase()
                        .replace(/\s+/g, "")
                        .trim()
                )
            );
        });
    }
    return res;
};

export const getSubjectOptions = (classSchedules, section, subjectsLists) => {
    let newSubjectList = {};
    const sectionId = section.id ? section.id : section.docId;

    const subjectOptions = Object.values(classSchedules || {}).filter(
        value => value.section.id === sectionId
    );
    Object.values(subjectsLists).forEach(
        subject => (newSubjectList[subject.docId ? subject.docId : subject.documentId] = subject)
    );

    const filteredSubjectOptions = subjectOptions.filter(
        subj => subj.subject.name !== MAPEH_SUBJECT
    );
    const subjectOptionsList = filteredSubjectOptions.map(subj => subj.subject.name);

    const mapehSubject = {
        classSchedName: "MAPEH",
        docId: "",
        id: "",
        name: "MAPEH",
    };

    const ifFoundMapeh = subjectOptionsList.some(r => MAPEH_SUBJECTS.includes(r));

    let newSubjectOptions = filteredSubjectOptions.map(subj => {
        const name = newSubjectList[subj.subject.id]?.name;

        return {
            id: subj.subject.id,
            name: name ? name : subj.subject.name,
            docId: subj.docId ? subj.docId : subj.documentId,
            classSchedName: subj.name,
        };
    });

    if (ifFoundMapeh) newSubjectOptions.push(mapehSubject);
    newSubjectOptions = sortCorrectedSubjects(newSubjectOptions, MAPEH_SUBJECTS_ORDER);

    return newSubjectOptions;
};

export const verboseQuarter = quarter => {
    let res = "";

    switch (quarter) {
        case "Q1":
            res = "(1ST GRADING)";
            break;

        case "Q2":
            res = "(2ND GRADING)";
            break;

        case "Q3":
            res = "(3RD GRADING)";
            break;

        case "Q4":
            res = "(4TH GRADING)";
            break;

        default:
            break;
    }

    return res;
};

export const isSubjectUseLetterGrade = (data, list) => {
    const newList = Object.values(list).map(listItem => ({
        id: listItem.docId ? listItem.docId : listItem.documentId,
        isUseLetterGrade: listItem.useLetterGrade ? listItem.useLetterGrade : false,
    }));

    const res = {
        ...data,
        docId: data.docId,
        grade: data.grade,
        finalGrade: data.grade,
        id: data.id,
        name: data.name,
        parent: data.parent,
    };

    let isSubjectLetterGrade;

    newList.forEach(subj => {
        if (subj.id === data.docId) {
            const isLetterGrade = subj?.isUseLetterGrade;

            if (isLetterGrade !== undefined || null) {
                isSubjectLetterGrade = isLetterGrade;
            } else {
                isSubjectLetterGrade = false;
            }
        }
    });

    if (isSubjectLetterGrade) res.grade = getSubjGradingTypeEquivalent(data.grade, "Letter");

    return res;
};

export const calculateMapehSubjectGrade = accMapehGrade => {
    return roundOffGrades(accMapehGrade.reduce((a, b) => a + b, 0) / 4);
};

export const computeSubjectAverage = sortedSubject => {
    const accSubjGrade = [];
    const accMapehGrade = [];

    Object.values(sortedSubject).forEach(subj => {
        if (!MAPEH_SUBJECTS.includes(subj.name) && subj.name !== MAPEH) {
            if (!isString(subj.grade)) {
                accSubjGrade.push(roundOffGrades(transmuteGrade(subj.grade)));
            }
        } else if (MAPEH_SUBJECTS.includes(subj.name)) {
            accMapehGrade.push(roundOffGrades(transmuteGrade(subj.grade)));
        }
    });

    if (accMapehGrade.length !== 0) {
        accSubjGrade.push(calculateMapehSubjectGrade(accMapehGrade));
    }

    const newAverage = roundOffGrades(convertToDecimal(computeAverage(accSubjGrade)));

    return newAverage;
};

export const computeAverage = accumulatedGradeList => {
    return accumulatedGradeList.reduce((a, b) => a + b, 0) / accumulatedGradeList.length;
};

export const modifySubjectStructure = (newSubjectOptions, subjects, subjectsLists) => {
    const newSubjects = {};
    subjects.forEach((subj, index) => (newSubjects[index] = subj));

    const modifiedSubjects = (newSubjectOptions || []).map(subjOptions => {
        let modifyGrade = 0;
        let modifyMapehGrade = 0;
        let id = "";
        let parent = "";

        Object.values(newSubjects).forEach(subj => {
            if (subj.id === subjOptions.docId) {
                modifyGrade = subj.grade;
                id = subj.id;
                parent = subj.parent;
            }

            if (subj.id === "MAPEH" && subj.name === "MAPEH") modifyMapehGrade = subj.grade;
        });

        const subjectStructure = {
            grade: subjOptions.name === MAPEH ? modifyMapehGrade : modifyGrade,
            id: id,
            name: subjOptions.name?.toUpperCase(),
            parent: parent,
            docId: subjOptions.id,
        };

        return isSubjectUseLetterGrade(subjectStructure, subjectsLists);
    });

    return modifiedSubjects;
};

export const modifyGeneralAverageStructure = (newSubjectOptions, subjects, subjectsLists) => {
    const modifiedSubjects = (newSubjectOptions || []).map(subjOptions => {
        let id = "";
        let parent = "";
        let modifyGrade = 0;
        let modifyMapehGrade = 0;
        let q1 = 0;
        let q2 = 0;
        let q3 = 0;
        let q4 = 0;

        Object.values(subjects).forEach(subj => {
            if (subj.id === subjOptions.docId) {
                id = subj.id;
                parent = subj.parent;
                modifyGrade = subj.grade;
                q1 = subj.q1;
                q2 = subj.q2;
                q3 = subj.q3;
                q4 = subj.q4;
            }

            if (subj.id === MAPEH && subj.name === MAPEH) {
                modifyMapehGrade = subj.grade;
                q1 = subj.q1;
                q2 = subj.q2;
                q3 = subj.q3;
                q4 = subj.q4;
            }
        });

        const subjectStructure = {
            docId: subjOptions.id,
            id: id,
            name: subjOptions.name?.toUpperCase(),
            parent: parent,
            grade: subjOptions.name === MAPEH ? modifyMapehGrade : modifyGrade,
            finalGrade: subjOptions.name === MAPEH ? modifyMapehGrade : modifyGrade,
            q1,
            q2,
            q3,
            q4,
        };

        return isSubjectUseLetterGrade(subjectStructure, subjectsLists);
    });

    return modifiedSubjects;
};

export const modifyFullSubjectStructure = (newSubjectOptions, subjects) => {
    const modifiedSubjects = (newSubjectOptions || []).map(subjOptions => {
        let id = "";
        let parent = "";
        let modifyQ1 = 0;
        let modifyQ2 = 0;
        let modifyQ3 = 0;
        let modifyQ4 = 0;

        Object.values(subjects).forEach(subj => {
            if (subj.id === subjOptions.docId) {
                id = subj.id;
                parent = subj.parent;
                modifyQ1 = subj.q1;
                modifyQ2 = subj.q2;
                modifyQ3 = subj.q3;
                modifyQ4 = subj.q4;
            }
        });

        const subjectStructure = {
            id: id,
            name: subjOptions.name,
            parent: parent,
            docId: subjOptions.id,
            q1: modifyQ1,
            q2: modifyQ2,
            q3: modifyQ3,
            q4: modifyQ4,
        };

        return subjectStructure;
    });

    return modifiedSubjects;
};

export const assignMapehGrade = (subjects, mapehGrade) => {
    return subjects.map(subject => {
        if (subject.name === MAPEH) {
            subject.grade = mapehGrade;
        }

        return subject;
    });
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatExportAllData = (gradeSlipData, isReportCard) => {
    const initialFormValues = isReportCard ? REPORT_CARD_INITIAL_FORM_VALUES : INITIAL_FORM_VALUES;

    const studentData = gradeSlipData.studentData?.docs
        .map(doc => doc.data())
        .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

    const newBranches = gradeSlipData.branches?.docs
        .map(doc => ({ ...doc.data(), id: doc.id }))
        .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

    const newSubjectLists = gradeSlipData.subjects?.docs.map(doc => ({
        ...doc.data(),
        documentId: doc.id,
    }));

    const newClassSchedule = gradeSlipData.classSchedule?.docs.map(doc => ({
        ...doc.data(),
        documentId: doc.id,
    }));

    // const exportValues = gradeSlipData.exportValues?.docs
    //     .map(doc => doc.data())
    //     .reduce((acc, curr) => ({ ...acc, [curr.studentId]: curr }), {});

    const exportValues = gradeSlipData.exportValues?.docs
        .map(doc => {
            const data = doc.data();

            if (
                data.type === REPORTCARD.toUpperCase() &&
                doc.id === `${data.studentId}-${data.schoolYearId}-${data.type}`
            ) {
                return doc.data();
            } else if (
                data.type === GRADESLIP.toUpperCase() &&
                doc.id === `${data.studentId}-${data.schoolYearId}-${data.quarter}-${data.type}`
            ) {
                return doc.data();
            }
        })
        .reduce((acc, curr) => {
            if (curr) {
                return { ...acc, [curr?.studentId]: curr };
            }
            return acc;
        }, {});

    const formData = Object.values(exportValues).length !== 0 ? exportValues : initialFormValues;

    const filteredStudents = gradeSlipData.students?.docs.filter(doc => {
        const data = doc.data();
        const { studentId } = data;
        return studentData[studentId];
    });

    const students = filteredStudents.map(doc => {
        const data = doc.data();
        const { studentId } = data;
        const studentFormData = formData[studentId]
            ? formData[studentId].formValues
            : initialFormValues;

        return {
            ...data,
            initialFormValues: studentFormData,
            exportValues: formData[studentId] || {
                ...INITIAL_EXPORT_FORM_VALUES,
                formValues: studentFormData,
            },
            lrn: studentData[studentId].lrn,
            birthdate: studentData[studentId].birthdate
                ? moment(studentData[studentId].birthdate, "YYYY-MM-DD").format("LL")
                : "N/A",
            level: studentData[studentId].level,
            branch: studentData[studentId].branch,
        };
    });

    return {
        branches: newBranches,
        students: students.reduce((acc, curr) => ({ ...acc, [curr.studentId]: curr }), {}),
        section: gradeSlipData.section?.data(),
        subjectLists: newSubjectLists,
        classSchedule: newClassSchedule,
        schoolYears: gradeSlipData.schoolYears?.docs.map(doc => doc.data()),
    };
};

export const correctGradeSlipSubjects = (subjects, classSchedules, section, subjectItems) => {
    const newSubjectOptions = getSubjectOptions(classSchedules, section, subjectItems);
    const sortedSubjectOptionsId = newSubjectOptions.map(subj => subj.docId);

    let accMapehGrade = [];

    let newSubjects = Object.values(subjects || {}).filter(value =>
        sortedSubjectOptionsId.includes(value.id)
    );
    newSubjects = modifySubjectStructure(newSubjectOptions, newSubjects, subjectItems);
    newSubjects = sortCorrectedSubjects(newSubjects, SUBJECT_ORDER);
    newSubjects.forEach(subject => {
        if (MAPEH_SUBJECTS.includes(subject.name)) {
            accMapehGrade.push(roundOffGrades(transmuteGrade(subject.grade)));
        }
    });
    newSubjects = assignMapehGrade(newSubjects, calculateMapehSubjectGrade(accMapehGrade));
    newSubjects.forEach(subject => {
        if (subject.parent === MAPEH || subject.name === MAPEH) {
            subject.name = subject.name.toUpperCase();
        }

        let transmutedGrade = isString(subject.grade)
            ? subject.grade
            : subject.name === MAPEH
            ? roundOffGrades(subject.grade)
            : subject.grade;

        subject.grade = transmutedGrade;
    });

    return newSubjects;
};

export const correctReportCardSubjects = (subjects, classSchedules, section, subjectItems) => {
    const newSubjectOptions = getSubjectOptions(classSchedules, section, subjectItems);
    const sortedSubjectOptionsId = newSubjectOptions.map(subj => subj.docId);

    let newSubjects = Object.values(subjects || {}).filter(value =>
        sortedSubjectOptionsId.includes(value.id)
    );
    newSubjects = modifyFullSubjectStructure(newSubjectOptions, newSubjects);

    const accMapehGradeQ1 = [];
    const accMapehGradeQ2 = [];
    const accMapehGradeQ3 = [];
    const accMapehGradeQ4 = [];

    newSubjects.forEach(subject => {
        const { name, q1, q2, q3, q4 } = subject;

        const convertedQ1 = getSubjGradingType(subject.docId, subjectItems, q1);
        const convertedQ2 = getSubjGradingType(subject.docId, subjectItems, q2);
        const convertedQ3 = getSubjGradingType(subject.docId, subjectItems, q3);
        const convertedQ4 = getSubjGradingType(subject.docId, subjectItems, q4);

        const transmutedQ1 = transmuteByQuarter(convertedQ1);
        const transmutedQ2 = transmuteByQuarter(convertedQ2);
        const transmutedQ3 = transmuteByQuarter(convertedQ3);
        const transmutedQ4 = transmuteByQuarter(convertedQ4);

        function transmuteByQuarter(quarterGrade) {
            return isString(quarterGrade) ? quarterGrade : transmuteGrade(quarterGrade);
        }

        if (MAPEH_SUBJECTS.includes(name)) {
            accMapehGradeQ1.push(transmutedQ1);
            accMapehGradeQ2.push(transmutedQ2);
            accMapehGradeQ3.push(transmutedQ3);
            accMapehGradeQ4.push(transmutedQ4);
        }

        subject.q1 = transmutedQ1;
        subject.q2 = transmutedQ2;
        subject.q3 = transmutedQ3;
        subject.q4 = transmutedQ4;
    });

    if (accMapehGradeQ1.length !== 0) {
        newSubjects.forEach(subject => {
            if (subject.name === MAPEH) {
                subject.q1 = calculateMapehSubjectGrade(accMapehGradeQ1);
                subject.q2 = calculateMapehSubjectGrade(accMapehGradeQ2);
                subject.q3 = calculateMapehSubjectGrade(accMapehGradeQ3);
                subject.q4 = calculateMapehSubjectGrade(accMapehGradeQ4);
            }
        });
    }

    newSubjects = sortCorrectedSubjects(newSubjects, SUBJECT_ORDER);

    return newSubjects;
};

export const isString = data => {
    return typeof data === "string";
};

export const roundOffGrades = grade => {
    return Math.round(grade);
};

export const transformRowValuesWithNotGraded = cells => {
    return cells.map(cell => {
        const rowViewValue = cell.props.children;
        if (rowIsGraded(rowViewValue)) {
            return cell;
        }
        return {
            ...cell,
            props: {
                children: NOT_GRADED,
            },
        };
    });
};

export const transformedToGradeWithNotGraded = grade => {
    if (+grade <= LOWEST_GRADE) {
        return NOT_GRADED;
    }
    return grade;
};

export const rowIsGraded = rowViewValue => {
    return rowViewValue.length === 0 || isString(rowViewValue) || rowViewValue > LOWEST_GRADE;
};

export const adjustScoresIfNotGraded = grades => {
    Object.keys(grades).forEach(key => {
        if (!isString(grades[key])) {
            grades[key] = transformedToGradeWithNotGraded(grades[key]);
        }
    });
    return grades;
};

export const convertToUpperCaseWithUnderscoreSpaces = string => {
    return string.replace(/\s/g, "_").toUpperCase();
};

export const convertToCapitalizeChangeToSpaces = string => {
    return string
        .toLowerCase()
        .split("_")
        .map(w => camelCaseToNormal(w))
        .toString()
        .replace(/,/g, " ");
};

export const isEmptyObject = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getReformatMobileNumber = value => {
    const mobileNumber = value.trim();
    let formattedNumber = "";

    if (mobileNumber && mobileNumber.length === 10) {
        formattedNumber = `+63${mobileNumber}`;
    } else if (mobileNumber && mobileNumber.length === 13) {
        formattedNumber = mobileNumber.replace("+63", "");
    }

    return formattedNumber;
};

export const replaceMobileNumber = value => {
    const mobileNumber = value.trim();
    let formattedNumber = "";

    if (mobileNumber && mobileNumber.length === 11) {
        formattedNumber = mobileNumber.replace("0", "");
    } else if (mobileNumber && mobileNumber.length === 13) {
        formattedNumber = mobileNumber.replace("+63", "");
    }

    return formattedNumber;
};

export const getAllValueFromCollection = (property, collection, subproperty = "") => {
    const values = [];
    collection.forEach(item => {
        if (!isUndefined(item)) {
            if (subproperty === "") {
                values.push(item[property]);
            } else {
                values.push(item[property][subproperty]);
            }
        }
    });
    return removeUndefinedItems(values);
};

export const removeUndefinedItems = collection => {
    const knownItems = [];
    collection.forEach(item => {
        if (!isUndefined(item)) {
            knownItems.push(item);
        }
    });
    return knownItems;
};

// Format name into <first name> <last name> format
export const formatNameToFirstLast = fullName => {
    let formattedName = "";
    const names = fullName.split(",");
    const firstMiddle = names[1].trim().split(" ");
    if (firstMiddle.length > 1) {
        firstMiddle.splice(firstMiddle.length - 1, 1);
    }
    return firstMiddle
        .join(" ")
        .trim()
        .concat(" ", names[0]);
};

export const getYearFromDateSeconds = seconds => {
    const milliseconds = seconds * 1000;

    return new Date(milliseconds).getFullYear();
};

export const convertFirebaseTimestampToDate = timestamp => {
    if (timestamp && !isEmptyObject(timestamp)) {
        return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
    }
};

export const shortenMoneyValue = value => {
    if (value >= 1000000000) {
        return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
        return value / 1000 + "K";
    } else {
        return value.toString();
    }
};

export const modifySubjectStructureWithoutLetterGrade = (newSubjectOptions, subjects) => {
    const newSubjects = {};
    subjects.forEach((subj, index) => (newSubjects[index] = subj));

    const modifiedSubjects = (newSubjectOptions || []).map(subjOptions => {
        let modifyGrade = 0;
        let modifyMapehGrade = 0;
        let id = "";
        let parent = "";

        Object.values(newSubjects).forEach(subj => {
            if (subj.id === subjOptions.docId) {
                modifyGrade = subj.grade;
                id = subj.id;
                parent = subj.parent;
            }

            if (subj.id === "MAPEH" && subj.name === "MAPEH") modifyMapehGrade = subj.grade;
        });

        const subjectStructure = {
            grade: subjOptions.name === MAPEH ? modifyMapehGrade : modifyGrade,
            id: id,
            name: subjOptions.name?.toUpperCase(),
            parent: parent,
            docId: subjOptions.id,
        };

        return subjectStructure;
    });

    return modifiedSubjects;
};

export const filterArrayByField = (arr, value, field, subField = "") => {
    if (arr.length > 0) {
        const tmp = [];
        arr.forEach(item => {
            const comp = subField === "" ? item[field] : item[field][subField];
            if (comp === value) {
                tmp.push(item);
            }
        });
        return tmp;
    }
};

export const GenerateSkeletonList = props => {
    const { quantity = 1, ...rest } = props;
    const numberOfArray = Array.from({ length: quantity }, (_, index) => index + 1);

    return numberOfArray.map(index => <Skeleton key={`generated-skeleton-${index}`} {...rest} />);
};

export const getOrdinalNumber = number => {
    if (number % 100 >= 11 && number % 100 <= 13) {
        return `${number}th`;
    }

    switch (number % 10) {
        case 1:
            return number + "st";
        case 2:
            return number + "nd";
        case 3:
            return number + "rd";
        default:
            return number + "th";
    }
};

export const removeAmountComma = number => {
    return number ? number.replace(/,/g, "") : number;
};
