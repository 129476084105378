// Theming of the app
// See https://material-ui.com/customization/theming/ for documentation

import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    overrides: {
        MuiButton: {
            root: {
                textTransform: "inherit",
            },
        },
    },
    palette: {
        common: {
            black: "#000",
            white: "#fff",
        },
        primary: {
            light: "#b26666",
            main: "#800000",
            dark: "#660000",
            contrastText: "#fff",
        },
        secondary: {
            light: "#efd880",
            main: "#EBCF61",
            dark: "#bca54d",
            contrastText: "#000",
        },
        tertiary: {
            main: "#4E5A71",
        },
        border: {
            loginTextField: "#E7E8EE",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
            dark: "#4E5A71",
        },
        error: {
            main: "#F50057",
        },
        grey: {
            custom1: "#9EA5B1",
            custom2: "#EEEEEE",
            custom3: "#E7E7E7",
            custom4: "#A4A4A4",
            custom5: "#959595",
            custom6: "#CECECE",
            custom7: "#C5C5C5",
        },
        background: {
            paper: "#FFF",
            default: "#fafafa",
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: "0.04",
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: "0.08",
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: "0.38",
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: "0.12",
            activatedOpacity: "0.12",
        },
    },
});

export { theme };
