import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import { authListen } from 'actions/auth';
import { firebaseConfig } from '../config/constants';
import appReducer from '../reducers';

firebase.initializeApp(firebaseConfig);
firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(error => {
    if (error.code === 'failed-precondition') {
      console.error(`[Error] ${error.name}: ${error.message}`);
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
    } else if (error.code === 'unimplemented') {
      console.error(`[Error] ${error.name}: ${error.message}`);
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

export const db = firebase.firestore();
export const functions = firebase.app().functions('asia-northeast1');
export const storageRef = firebase.storage().ref();

const rootReducer = combineReducers({
  root: appReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  stateReconciler: autoMergeLevel2,
};

const isDevEnv = process.env.NODE_ENV !== 'development';
const middleware = isDevEnv ? [thunkMiddleware] : [thunkMiddleware, logger];

const enhancer = composeWithDevTools(applyMiddleware(...middleware));
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, {}, enhancer);
const persistor = persistStore(store);

store.dispatch(authListen());

export default function configureStore() {
  return { store, persistor };
}
